import {RateMap, getRates} from './lib/contentApiRatesClient';

function replaceRate(lender: string, rateMap: RateMap) {
    let matches = document.querySelectorAll<HTMLElement>(`.dynamic-rate-${lender.toLowerCase()} .dynamic-rate-value`)
    for (var i=0; i<matches.length; i++) {
        try {
            const val = rateMap[lender].rate
            const valString = val.toFixed(3).toString();
            const startValString = (val + 2).toFixed(3).toString();
            let currentMatch = matches[i]
            if(lender === 'lower')
            {
              currentMatch.dataset.countUpToPrecision = '3'
              currentMatch.dataset.countUpTo = valString
              currentMatch.dataset.startValue = startValString
              matches[i].textContent = startValString
            }
            else
            {
              matches[i].textContent = valString
            }
        }
        catch(_e) {
            console.group("Lender Rate Error: " + lender)
            console.log(rateMap)
        }
    }
}

function replaceRateDiff(lender: string, rateMap: RateMap) {
    let matches = document.querySelectorAll<HTMLElement>(`.dynamic-rate-${lender.toLowerCase()} .dynamic-rate-diff`)
    const rateDiff = rateMap['bankMarketAvg'].rate - rateMap[lender].rate;
    const arrowsToHide = rateDiff === 0 ?
      document.querySelectorAll<HTMLElement>(`.dynamic-rate-${lender.toLowerCase()} .dynamic-rate-increase, .dynamic-rate-${lender.toLowerCase()} .dynamic-rate-decrease`)
      : rateDiff > 0 ?
        document.querySelectorAll<HTMLElement>(`.dynamic-rate-${lender.toLowerCase()} .dynamic-rate-increase`)
        : document.querySelectorAll<HTMLElement>(`.dynamic-rate-${lender.toLowerCase()} .dynamic-rate-decrease`)

    for (var i=0; i<arrowsToHide.length; i++) {
        try {
          arrowsToHide[i].style.display = "none"
        }
        catch(_e) {
            console.group("Lender RateDiff Error: " + lender)
            console.log(rateMap)
        }
    }
    for (var i=0; i<matches.length; i++) {
        try {
            const absVal = Math.abs(rateDiff)
            const absValString = absVal.toFixed(2).toString()
            const startValString = (absVal + 2).toFixed(2).toString()
            let currentMatch = matches[i]
            if(lender === 'lower')
            {
              currentMatch.dataset.countUpTo = absValString
              currentMatch.dataset.countUpToPrecision = '2'
              currentMatch.dataset.startValue = startValString
              matches[i].textContent = startValString
            }
            else
            {
              matches[i].textContent = absValString
            }
        }
        catch(_e) {
            console.group("Lender RateDiff Error: " + lender)
            console.log(rateMap)
        }
    }
}

function replaceApr(lender: string, rateMap: RateMap) {
    let matches = document.querySelectorAll<HTMLElement>(`.dynamic-apr-${lender.toLowerCase()} .dynamic-rate-value`)
    for (var i=0; i<matches.length; i++) {
        try {
          const val = rateMap[lender].apr;
          const valString = val.toFixed(3).toString()
          const startValString = (val + 2).toFixed(3).toString()
          let currentMatch = matches[i]
          if(lender === 'lower')
          {
            currentMatch.dataset.countUpToPrecision = '3'
            currentMatch.dataset.countUpTo = valString
            currentMatch.dataset.startValue = startValString
            matches[i].textContent = startValString
          }
          else
          {
            matches[i].textContent = valString
          }
        }
        catch(_e) {
            console.group("Lender APR Error: " + lender)
            console.log(rateMap)
        }
    }
}

function replaceAprDiff(lender: string, rateMap: RateMap) {
    const matches = document.querySelectorAll<HTMLElement>(`.dynamic-apr-${lender.toLowerCase()} .dynamic-rate-diff`)
    const rateDiff = rateMap['bankMarketAvg'].apr - rateMap[lender].apr;
    const arrowsToHide = rateDiff === 0 ?
        document.querySelectorAll<HTMLElement>(`.dynamic-apr-${lender.toLowerCase()} .dynamic-rate-increase, .dynamic-apr-${lender.toLowerCase()} .dynamic-rate-decrease`)
        : rateDiff > 0 ?
            document.querySelectorAll<HTMLElement>(`.dynamic-apr-${lender.toLowerCase()} .dynamic-rate-increase`)
            : document.querySelectorAll<HTMLElement>(`.dynamic-apr-${lender.toLowerCase()} .dynamic-rate-decrease`)

    for (var i=0; i<arrowsToHide.length; i++) {
        try {
          arrowsToHide[i].style.display = "none"
        }
        catch(_e) {
            console.group("Lender RateDiff Error: " + lender)
            console.log(rateMap)
        }
    }
    for (var i=0; i<matches.length; i++) {
        try {
            const absVal = Math.abs(rateDiff);
            const absValString = absVal.toFixed(2).toString()
            const startValueString = (absVal + 2).toFixed(3).toString()
            let currentMatch = matches[i]
            if(lender === 'lower')
            {
              currentMatch.dataset.countUpTo = absVal.toFixed(2).toString()
              currentMatch.dataset.startValue = absValString
              currentMatch.dataset.countUpToPrecision = '2'
              currentMatch.dataset.startValue = startValueString
              matches[i].textContent = startValueString
            }
            else
            {
              matches[i].textContent = absValString
            }
        }
        catch(_e) {
            console.group("Lender RateDiff Error: " + lender)
            console.log(rateMap)
        }
    }
}

function replaceDetails(lender: string, rateMap: RateMap) {
  const lenderMap = rateMap[lender]
  Object.keys(lenderMap)
    .forEach(prop => {
      const propVal = lenderMap[prop]
      if(propVal)
      {
        const foundEls = document.querySelectorAll<HTMLElement>(`.dynamic-ratetable-${prop.toLowerCase()}-${lender.toLowerCase()}`)
        foundEls.forEach((foundEl) => {
          foundEl.textContent = propVal
        })
      }
    })
}

// Duplicating replaceRefiRate on the quick for a hotfix - this script used to be 8 lines long. 
// TODO: refactor the whole thing so it's not 80% duplicative and can handle more scenarios.
function replaceRefiRate(lender: string, rateMap: RateMap) {
  let matches = document.querySelectorAll<HTMLElement>(`.dynamic-refirate-${lender.toLowerCase()} .dynamic-rate-value`)
  for (var i=0; i<matches.length; i++) {
      try {
          const val = rateMap[lender].refiRate
          const valString = val.toFixed(3).toString();
          const startValString = (val + 2).toFixed(3).toString();
          let currentMatch = matches[i]
          if(lender === 'lower')
          {
            currentMatch.dataset.countUpToPrecision = '3'
            currentMatch.dataset.countUpTo = valString
            currentMatch.dataset.startValue = startValString
            matches[i].textContent = startValString
          }
          else
          {
            matches[i].textContent = valString
          }
      }
      catch(_e) {
          console.group("Lender Refi Rate Error: " + lender)
          console.log(rateMap)
      }
  }
}
export default async () => {
    console.log('updating rates found');
    const rateMap = await getRates();
    ["lower", "wells", "quicken", "bankMarketAvg"].forEach(lender => {
        replaceRate(lender, rateMap);
        replaceRateDiff(lender, rateMap);
        replaceApr(lender, rateMap);
        replaceAprDiff(lender, rateMap);
        replaceDetails(lender, rateMap);
    });
    replaceRefiRate("lower", rateMap);
}
