export default async () => {
  if(process.env.NODE_ENV === 'production')
  {
    const fun = (a,b) => {
      var c=window;c.SessionStackKey=a,c[a]=c[a]||{t:b,q:[]};
      for(var d=["start","stop","identify","getSessionId","log","setOnDataCallback"],e=0;e<d.length;e++)
        (b) => {
          c[a][b]=c[a][b]||function(){c[a].q.push([b].concat([].slice.call(arguments,0)));
        }}
      (d[e])
      var f=document.createElement("script");
      f.async=1,
      f.crossOrigin="anonymous",
      f.src="https://cdn.sessionstack.com/sessionstack.js";
      var g=document.getElementsByTagName("script")[0];
      g.parentNode.insertBefore(f,g)
    }
    fun("SessionStack","7047cc18da2f44f192c6e8762b11ab4e");
  }
}
