import {get, set} from './lib/cookies'
import {parse} from './lib/query-string'

export function getUtmCookie(str){
  const utmContentVal = get(str)
  return utmContentVal === "null" ? null : utmContentVal
}

function getTLD(){
  if(window.location.hostname === "localhost") return window.location.hostname
  const levels = window.location.hostname.split(".")
  return `.${levels[levels.length - 2]}.${levels[levels.length - 1]}`
}

function getGaClientId(){
  try{
    return window.ga.getAll()[0].get('clientId')
  }
  catch(e){
    return
  }
}

const getFirstIfArray = (cookie: string | string[] | null | undefined) : string | null | undefined => {
  if(cookie && Array.isArray(cookie))
  {
    return cookie[0]
  } else {
    return cookie as string
  }
}

const useTracking = () => {
  const cookieOpts = {
    domain: getTLD()
  }

  const {
    search_id,
    loan_type,
    gbraid: windowGbraid,
    gclid: windowGclid,
    utm_content,
    utm_campaign,
    utm_source,
    utm_medium,
    utm_term,
    wbraid: windowWbraid
  } = parse(window.location.search as string)

  const clientId = getGaClientId()
  const cookieClientId = getUtmCookie('clientId')
  const searchId = getFirstIfArray(search_id)
  const loanType = getFirstIfArray(loan_type)
  const gbraid = getFirstIfArray(windowGbraid)
  const gclid = getFirstIfArray(windowGclid)
  const utmContent = getFirstIfArray(utm_content)
  const utmCampaign = getFirstIfArray(utm_campaign)
  const utmSource = getFirstIfArray(utm_source)
  const utmMedium = getFirstIfArray(utm_medium)
  const utmTerm = getFirstIfArray(utm_term)
  const wbraid = getFirstIfArray(windowWbraid)
  const lastReferrer = (document.referrer === "" ? null : document.referrer)

  if((
    utmContent ||
    utmCampaign ||
    utmSource ||
    utmMedium ||
    utmTerm ||
    lastReferrer
  ) && ((
    !getUtmCookie('utm_content') &&
    !getUtmCookie('utm_campaign') &&
    !getUtmCookie('utm_source') &&
    !getUtmCookie('utm_medium') &&
    !getUtmCookie('utm_term')
  ) || (cookieClientId && clientId && cookieClientId !== clientId)))
  {
    if(clientId){
      set('clientId', clientId, cookieOpts)
    }
    if(utmContent) {
      set('utm_content', utmContent, cookieOpts)
    }
    if(utmCampaign){
      set('utm_campaign', utmCampaign, cookieOpts)
    }
    if(utmSource){
      set('utm_source', utmSource, cookieOpts)
    }
    if(utmMedium){
      set('utm_medium', utmMedium, cookieOpts)
    }
    if(utmTerm) {
      set('utm_term', utmTerm, cookieOpts)
    }
    if(lastReferrer) {
      set('last_referrer', lastReferrer, cookieOpts)
    }
  }

  if(gclid){
    set('gclid', gclid, cookieOpts)
  }

  if(gbraid){
    set('gbraid', gbraid, cookieOpts)
  }

  if(wbraid){
    set('wbraid', wbraid, cookieOpts)
  }

  if(search_id && loan_type){
    const ratekick = {'search_id': searchId, 'loan_type': loanType}
    set('ratekick', JSON.stringify(ratekick), cookieOpts)
  }

  return {
    search_id,
    loan_type,
    gbraid,
    gclid,
    utmContent,
    utmCampaign,
    utmSource,
    utmMedium,
    lastReferrer,
    utmTerm,
    clientId,
    cookieClientId,
    wbraid
  }
}

export default useTracking
