import {LendingRates, LendingRate, getLendingRates} from './lib/contentApiLendingRatesClient'

// Filter for finding a relevant rate scenario given lender/term/loantype
const isCompatibleRateScenario = function isCompatibleRateScenario(
    lender: string,
    term: string,
    loantype: string,
    ) {
    return function(element: LendingRate) {
        return element.lender === lender && element.term === parseInt(term) && element.loanType === loantype
    }
}

// Finds elements that needs a certain value from rate data and injects appropriate textContext and data
const replaceValues = function replaceValues(
    rateDataElement: HTMLElement,
    scenarioValue: any,
    valueClass: string,
    usesCountUp = false,
    precision = '3',
    ) {
    if (scenarioValue === undefined) { return false; } // not found in api response
    for (let injectable of rateDataElement.querySelectorAll<HTMLElement>(valueClass)) {
        if (usesCountUp) { 
            injectable.dataset.countUpToPrecision = precision
            injectable.dataset.countUpTo = `${scenarioValue}`
            injectable.dataset.startValue = (2 + +scenarioValue).toFixed(+precision).toString()
            injectable.textContent = injectable.dataset.startValue
        } else {
            injectable.textContent = `${scenarioValue}`
        }
    }
}

// For derived values (like the difference between rates), replaces values and additionally updates any trick arrows
const updateComparisons = function updateComparisons(
    rateDataElement: HTMLElement,
    rateDifference: number,
    upArrowClass: string,
    downArrowClass: string,
    diffClass: string,
    usesCountUp = false,
    ) {
    const arrowsToHide = rateDifference === 0 ?
        rateDataElement.querySelectorAll<HTMLElement>(`${upArrowClass}, ${downArrowClass}`)
        : rateDifference > 0 ?
            rateDataElement.querySelectorAll<HTMLElement>(`${upArrowClass}`)
            : rateDataElement.querySelectorAll<HTMLElement>(`${downArrowClass}`)
    for (let arrow of arrowsToHide) {
        arrow.style.display = 'none'
    }
    replaceValues(rateDataElement, Math.abs(rateDifference).toFixed(2).toString(), diffClass, usesCountUp, '2')
}

export default async () => {
    console.log('Retrieving and updating page with latest rates')
    const lendingRates: LendingRates = await getLendingRates()

    for (let target of document.querySelectorAll<HTMLElement>(`.ratedata`)) {
        // Cheesy try-catch, but we actually do want to just do nothing if there is a data problem
        try {
            const { lender, term, loantype } = target.dataset
            const scenario = lendingRates.find(isCompatibleRateScenario(lender, term, loantype))
            const marketAvg = lendingRates.find(isCompatibleRateScenario("bankMarketAvg", term, loantype))    
            replaceValues(target, scenario.currentAsOfDate, '.ratedata-currentasofdate')
            replaceValues(target, scenario.loanAmount, '.ratedata-loanamount')
            replaceValues(target, scenario.payment, '.ratedata-payment')
            replaceValues(target, scenario.discountPoints, '.ratedata-discountpoints')
            replaceValues(target, scenario.rate, '.ratedata-rate', (scenario.lender === "lower"))
            updateComparisons(target, (+marketAvg.rate - +scenario.rate), '.ratedata-rate-increase', '.ratedata-rate-decrease', '.ratedata-rate-diff', (scenario.lender === "lower"))
            replaceValues(target, scenario.apr, '.ratedata-apr', (scenario.lender === "lower"))
            updateComparisons(target, (+marketAvg.apr - +scenario.apr), '.ratedata-apr-increase', '.ratedata-apr-decrease', '.ratedata-apr-diff', (scenario.lender === "lower"))    
        } catch (_e) {
            continue
        }
    }
}
