export interface LendingRate {
    lender: string
    term: number
    loanType: string
    currentAsOfDate: string
    loanAmount?: number
    payment?: number
    discountPoints?: number
    rate: string
    apr: string
}

export interface LendingRates extends Array<LendingRate> { }

export async function getLendingRates(): Promise<LendingRates> {
    // Fetches Content
    // FIXME: This should be abstracted out some kind of env variable, but we don't have
    //        a pattern for that with parcel.
    const result = await fetch('https://platform.lower.com/api/v1/Content/LendingRates').then(function (response) {
        if (response.ok) {
            return response.json()
        }
        console.warn('LendingRates API Unavailable')
    })
    if (!result) { return [] }

    // Compose LendingRate collection and munge some value formatting for this use-case
    let lendingRates: LendingRates = []
    for (let scenario of result) {
        let lendingRate: LendingRate = scenario
        let d = new Date(scenario.currentAsOfDate)
        lendingRate.currentAsOfDate = [
            d.getFullYear(),
            ('0' + (d.getMonth() + 1)).slice(-2),
            ('0' + d.getDate()).slice(-2)
        ].join('-');
        lendingRate.rate = scenario.rate.toFixed(3).toString()
        lendingRate.apr = scenario.apr.toFixed(3).toString()
        lendingRates.push(lendingRate)
    }

    return lendingRates
}