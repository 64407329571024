export default () => {
  const trackingElements = document.querySelectorAll(`[data-lower-click-tag-tracking]`);
  trackingElements.forEach(ele => {
    if (ele?.dataset?.lowerClickTagTracking && ele.dataset.lowerClickTagTracking !== "") {
      ele.addEventListener("click", () => {
        if(typeof fbq !== 'undefined')
        {
          fbq('trackCustom', ele.dataset.lowerClickTagTracking)
        } else {
          console.log(`fbq('trackCustom', '${ele.dataset.lowerClickTagTracking})'`)
        }

        if(typeof snaptr !== 'undefined')
        {
          snaptr('track', 'VIEW_CONTENT', {'description': `click:${ele.dataset.lowerClickTagTracking}`});
        } else {
          console.log(`snaptr('track', 'VIEW_CONTENT', {'description': 'click:${ele.dataset.lowerClickTagTracking})'}`)
        }

        if(typeof ttq !== 'undefined')
        {
          ttq.track(ele.dataset.lowerClickTagTracking);
        } else {
          console.log(`ttq.track(${ele.dataset.lowerClickTagTracking})`)
        }
      });
    }
  });
}
