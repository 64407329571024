import smartscript from './lib/appsflyer-onelink-smart-script.js'
import {get, set} from "./lib/cookies";
export default () => {
  smartscript()
  const onelinkParamsGenerator =  new window.AF.OneLinkParamGenerator(
    {
      pidKeysList: ['af_pid', 'utm_source'],
      campaignKeysList: ['af_c', 'af_campaign', 'utm_campaign'],
      campaignContentKeysList: ['af_ad', 'utm_content'],
      campaignMediumKeysList: ['af_adset', 'utm_medium']
    });

  const lastOnelinkParams = onelinkParamsGenerator.generateUrlParams();

  if(lastOnelinkParams) {
    set('last_onelink_params', lastOnelinkParams)
  }

  const currentLastOnelinkParams = get('last_onelink_params');

  const trackingElements = document.querySelectorAll(`a[href*="lower.onelink.me"]`)
  trackingElements.forEach(ele => {
    const currentUrl = ele.getAttribute('href').split('?')[0]
    const onelinkGenerator =  new window.AF.OneLinkUrlGenerator(
      {oneLinkURL: currentUrl,
        pidKeysList: ['af_pid', 'utm_source'],
        campaignKeysList: ['af_c', 'af_campaign', 'utm_campaign'],
        campaignContentKeysList: ['af_ad', 'utm_content'],
        campaignMediumKeysList: ['af_adset', 'utm_medium'],
        ...(currentLastOnelinkParams && {forceOnelinkParams: currentLastOnelinkParams})
      });
    const url = onelinkGenerator.generateUrl();

    if (url) {
      ele.setAttribute('href', url)
    }
  })
}
