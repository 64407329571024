export interface RateMap {
    [key: string]: {
        rate: number
        apr: number
        refiRate?: number
    }
}

export async function getRates () {
    let rates: RateMap = { };

    const response = await fetch('https://platform.lower.com/api/v1/Content/Airtables/lenders');
    const result = await response.json();

    // loop through the result and build a rate matrix
    // term, name, discountPoints, loanAmount, currentAsOfDate, rate, refiRate, payment
    result.records.forEach(record => {
        if (record.term == 30) {
            rates[record.name] = record
        }
    });
    return rates;
}