// Create a function that returns a function dedicated to
// this postMessage flow
function createPostMessageToLowerPersonalApply() {
  var receiveMessage = void 0;
  return function postMessageToLowerPersonalApply(data) {
    // unbind if you called it already (shouldn't happen if you guard against resending
    // data to Lower Personal Apply)
    if (receiveMessage) {
      window.removeEventListener("message", receiveMessage);
    }

    // This receiver callback function will wait for
    // the READY_TO_RECEIVE_MESSAGES event.data.type
    // to be sent from Lower Personal Apply when it is officially
    // ready to accept incoming messages
    receiveMessage = function receiveMessage(event) {
      // Check for valid sender domain (Lower app)
      if (!/^https?:\/\/(app\.)?lower\.com/.test(event.origin)) {
        return;
      }
      // If you receive the READY_TO_RECEIVE_MESSAGES,
      // that means you can send me initialization data!
      if (event.data.type === 'READY_TO_RECEIVE_MESSAGES') {
        tab.postMessage(data, 'https://app.lower.com');

        window.removeEventListener("message", receiveMessage)
      }
    };

    // bind function to listen to when Lower Personal Apply sends
    // the READY_TO_RECEIVE_MESSAGES message (see receiveMessage above)
    window.addEventListener("message", receiveMessage, false);

    // Open a new tab in the browser with Lower Personal Apply's first step (aliased for your convenience),
    // and keep a reference in the 'tab' variable, that will be used in the receiveMessage function
    const loanTypeMap = {
      'Cash-Out Refinance': 'refinance',
      'Purchase': 'purchase',
      'Heloc': 'heloc',
    }
    var tab = window.open(`https://app.lower.com/apply/step-alias/start?${loanTypeMap[data.data.fields.loanPurposeType]}=1`, '_blank');
  };
}

export default async () => {
  var sendPostMessageData = createPostMessageToLowerPersonalApply()
  var elements = document.querySelectorAll<HTMLElement>('[data-loan-purpose]')
  var onPaLinkClick = (e) => {
    var loanPurposeType = e.currentTarget.dataset.loanPurpose
    var data = {
      type: 'FILL_PERSONAL_APPLY_FIELDS',
      data: {
        fields: {
          loanPurposeType // 'Cash-Out Refinance', 'Purchase', 'Heloc'
        }
      }
    };
    sendPostMessageData(data)
    e.preventDefault();
  }

  for (var i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', onPaLinkClick, false);
  }
}
