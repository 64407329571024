const isInViewport = (elem) => {
  var bounding = elem.getBoundingClientRect()
  return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

type AnimatingElement = {
  element: HTMLElement,
  value: number,
  finalValue: number,
  precision: number,
  isDone: boolean
}

export default async () => {
  const countUpElements = document.querySelectorAll<HTMLElement>('[data-count-up-to]')
  let animatingElements : AnimatingElement[] = []
  const incrementBy = 0.017
  const velocity = 1
  let isAnimating = false

  const animateElements = () => {
    const elsToAnimate = animatingElements.filter((ae) => !ae.isDone)
    if(elsToAnimate.length > 0)
    {
      elsToAnimate.forEach((ae) => {
        if(ae.value > ae.finalValue)
        {
          const newVal = ae.value - incrementBy
          ae.value = newVal < ae.finalValue ? ae.finalValue : newVal
        }
        else
        {
          const newVal = ae.value + incrementBy
          ae.value = newVal > ae.finalValue ? ae.finalValue : newVal
        }
      })
      setTimeout(animateElements, velocity)
    }
    else
    {
      isAnimating = false
    }
  }

  const startAnimations = () => {
    if(!isAnimating) {
      isAnimating = true
      animateElements()
      function step() {
        const elsToAnimate = animatingElements.filter((ae) => !ae.isDone)
        if(elsToAnimate.length > 0)
        {
          animatingElements.filter((ae) => !ae.isDone).forEach((ae) => {
            ae.element.textContent = ae.value.toFixed(ae.precision)
            ae.isDone = ae.value === ae.finalValue
          })
          requestAnimationFrame(step)
          console.log('step')
        }
      }
      requestAnimationFrame(step)
    }
  }

  const runViewportAnimations = () => {
    console.log('scroll')
    if(animatingElements.length === countUpElements.length)
    {
      window.removeEventListener('scroll', runViewportAnimations, false)
    }
    countUpElements.forEach((e) => {
      if (isInViewport(e) && animatingElements.findIndex((animatedElement) => animatedElement.element === e) === -1) {
        animatingElements.push({
          element: e,
          value: parseFloat(e.dataset.startValue) || 0,
          precision: parseInt(e.dataset.countUpToPrecision),
          finalValue: parseFloat(e.dataset.countUpTo),
          isDone: false
        })
        startAnimations()
      }
    })
  }

  window.addEventListener('scroll', runViewportAnimations, false)
  setTimeout(() => runViewportAnimations(), 500)
}
