interface CookieOptions {
  expires?: number | Date | string;
  domain?: string;
  path?: string;
  secure?: boolean;
  httponly?: boolean;
  samesite?: "" | "Strict" | "Lax";
}
interface StringMap { [key: string]: any; }

const defaults = {}

const set = function(name: string, value: string, options?: CookieOptions) : void {
  // Retrieve options and defaults
  var opts = options || {};

  // Apply default value for unspecified options
  var expires  = opts.expires  || defaults.expires;
  var domain   = opts.domain   || defaults.domain;
  var path     = opts.path     !== undefined ? opts.path     : (defaults.path !== undefined ? defaults.path : '/');
  var secure   = opts.secure   !== undefined ? opts.secure   : defaults.secure;
  var httponly = opts.httponly !== undefined ? opts.httponly : defaults.httponly;
  var samesite = opts.samesite !== undefined ? opts.samesite : defaults.samesite;

  // Determine cookie expiration date
  // If succesful the result will be a valid Date, otherwise it will be an invalid Date or false(ish)
  var expDate = expires ? new Date(
      // in case expires is an integer, it should specify the number of days till the cookie expires
      typeof expires === 'number' ? new Date().getTime() + (expires * 864e5) :
      // else expires should be either a Date object or in a format recognized by Date.parse()
      expires
  ) : false;

  // Set cookie
  document.cookie = name.replace(/[^+#$&^`|]/g, encodeURIComponent)                // Encode cookie name
  .replace('(', '%28')
  .replace(')', '%29') +
  '=' + value.replace(/[^+#$&/:<-\[\]-}]/g, encodeURIComponent) +                  // Encode cookie value (RFC6265)
  (expDate && expDate.getTime() >= 0 ? ';expires=' + expDate.toUTCString() : '') + // Add expiration date
  (domain   ? ';domain=' + domain     : '') +                                      // Add domain
  (path     ? ';path='   + path       : '') +                                      // Add path
  (secure   ? ';secure'               : '') +                                      // Add secure option
  (httponly ? ';httponly'             : '') +                                      // Add httponly option
  (samesite ? ';samesite=' + samesite : '');                                       // Add samesite option
};

const get = function(name: string): string | null {
  var cookies = document.cookie.split(';');

  // Iterate all cookies
  while(cookies.length) {
    var cookie = cookies.pop();
    if(cookie) {
      // Determine separator index ("name=value")
      var separatorIndex = cookie.indexOf('=');

      // IE<11 emits the equal sign when the cookie value is empty
      separatorIndex = separatorIndex < 0 ? cookie.length : separatorIndex;

      var cookie_name = decodeURIComponent(cookie.slice(0, separatorIndex).replace(/^\s+/, ''));

      // Return cookie value if the name matches
      if (cookie_name === name) {
        return decodeURIComponent(cookie.slice(separatorIndex + 1));
      }
    }
  }

  // Return `null` as the cookie was not found
  return null;
};

const erase = function(name: string, options?: CookieOptions): void {
  set(name, '', {
      expires:  -1,
      domain:   options && options.domain,
      path:     options && options.path,
      secure:   false,
      httponly: false
    }
  );
};

const all = function() {
  var all = {} as StringMap;
  var cookies = document.cookie.split(';');

  // Iterate all cookies
  while(cookies.length) {
    var cookie = cookies.pop();
    if(cookie)
    {
      // Determine separator index ("name=value")
      var separatorIndex = cookie.indexOf('=');

      // IE<11 emits the equal sign when the cookie value is empty
      separatorIndex = separatorIndex < 0 ? cookie.length : separatorIndex;

      // add the cookie name and value to the `all` object
      var cookie_name = decodeURIComponent(cookie.slice(0, separatorIndex).replace(/^\s+/, ''));
      all[cookie_name] = decodeURIComponent(cookie.slice(separatorIndex + 1));
    }
  }

  return all;
};

export {
  set, erase, get, all
}
