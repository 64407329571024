export default () => {
  const trackingElements = document.querySelectorAll(`[data-lower-conversion-tracking]`);
  trackingElements.forEach(ele => {
    if (ele?.dataset?.lowerConversionTracking && ele.dataset.lowerConversionTracking !== "") {
      ele.addEventListener("click", () => {
        if(typeof ga !== 'undefined')
        {
          gtag('event', 'conversion', {'send_to': ele.dataset.lowerConversionTracking});
        } else {
          console.log(`gtag('event', 'conversion', {'send_to': '${ele.dataset.lowerConversionTracking}})'`)
        }
      });
    }
  });
}
