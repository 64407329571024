import updateRate from './updateRate';
import updateLendingRates from './updateLendingRates';
import personalApplyLoanTypeLink from './personalApplyLoanTypeLink'
import countUpElements from './countUpElements'
import formSubmissionTracking from './formSubmissionTracking'
import sessionStack from './sessionStack'
import clickTagTracking from './clickTagTracking'
import conversionTracking from './conversionTracking'
import appsflyer from './appsflyer'

(function() {
  var lastTime = 0;
  var vendors = ['ms', 'moz', 'webkit', 'o'];
  for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
      window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame']
                                 || window[vendors[x]+'CancelRequestAnimationFrame'];
  }

  if (!window.requestAnimationFrame)
      window.requestAnimationFrame = function(callback, element) {
          var currTime = new Date().getTime();
          var timeToCall = Math.max(0, 16 - (currTime - lastTime));
          var id = window.setTimeout(function() { callback(currTime + timeToCall); },
            timeToCall);
          lastTime = currTime + timeToCall;
          return id;
      };

  if (!window.cancelAnimationFrame)
      window.cancelAnimationFrame = function(id) {
          clearTimeout(id);
      };
}());

const run = async () => {
  formSubmissionTracking()
  sessionStack()
  await updateRate()
  await updateLendingRates()
  await personalApplyLoanTypeLink()
  countUpElements()
  clickTagTracking()
  conversionTracking()
  appsflyer()
}

document.addEventListener('DOMContentLoaded', () => {
  run()
}, false);
